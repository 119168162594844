import { Link } from "@StarberryUtils";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React,{useState, useEffect} from "react";
import {Button, Card, Accordion,Nav, Container } from 'react-bootstrap';
import { navigate } from "@reach/router"
import { LOCAL_LOVE_PAGE_URL} from '../common/site/constants'
import logoImg from "../../images/mg-logo-dark.svg";
import logoBlack from "../../images/mg-logo-black.svg";

import lovelogoImg from "../../images/mg-logo-black.svg";
import lovelogoBlack from "../../images/mg-logo-dark.svg";

import careerlogoImg from "../../images/mg-careers-logo-dark.svg";
import careerlogoBlack from "../../images/mg-careers-logo-light.svg";

import myLogoImg from "../../images/my-nav-logo.svg";
import navLogoWhite from "../../images/my-mg-logo-white.svg";
import logoImgLove from "../../images/nav-logo-love-new.svg";
//import "animate.css/animate.min.css";
import  "../header/header.scss";
import $ from "jquery";
import SearchOverlay from "../searchOverlay/SearchOverlay"

import { GetURL } from "../common/site/functions";

const Header = ( props ) => {

  const [removeClassname, setRemoveClassname] = useState(false)
  const [addclassname,setAddclassname] = useState(false)
  const [hoverclass,setHoverclass] = useState(false)
  const [hoverclass2,setHoverclass2] = useState(false)
  const [hoverclass3,setHoverclass3] = useState(false)
  const [hoverclass4,setHoverclass4] = useState(false)
  const [hoverclass5,setHoverclass5] = useState(false)
  const [hoverclass6,setHoverclass6] = useState(false)
  const [submenuclass, setSubmenuclass] = useState(false)
  const [navLinkClass,setNavLinkClass] = useState(false)
  const [navLinkClass2,setNavLinkClass2] = useState(false)
  const [navLinkClass21,setNavLinkClass21] = useState(false)
  const [navLinkClass3,setNavLinkClass3] = useState(false)
  const [navLinkClass31,setNavLinkClass31] = useState(false)
  const [navLinkClass4,setNavLinkClass4] = useState(false)
  const [navLinkClass5,setNavLinkClass5] = useState(false)
  const [navLinkClass6,setNavLinkClass6] = useState(false)
  const [scroll, setScroll] = useState(false);


  const [logo, setLogo] = useState('')

  const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || '';

  let lastScrollY = 0
  const removeClass = (e) => {
      setSubmenuclass(false)
      setAddclassname(true)
      setRemoveClassname(true)
      setNavLinkClass(false)
      setNavLinkClass2(false)
      setNavLinkClass21(false)
      setNavLinkClass3(false)
      setNavLinkClass31(false)
      setNavLinkClass4(false)
      setNavLinkClass5(false)
      setNavLinkClass6(false)
  }

    const [toggleActive, settoggleActive] = useState(null)
    const toggleClick = (event) => {
    if(toggleActive === event){
    settoggleActive(null)
    }
    else {
    settoggleActive(event)

    }

    }

useEffect(() => {

  const pageurl = typeof window !== 'undefined' ? window.location.href : ''

  const hash = typeof window !== 'undefined' ? window.location.pathname : ''
  if(hash == "/property/for-sale" || hash == "/property/for-sale/") {
    navigate(`/property/for-sale/in-london/`)
  }
  else if(hash == "/property/to-rent" || hash == "/property/to-rent/") {
    navigate(`/property/to-rent/in-london/`)
  }
  else if(hash == "/property/new-homes/for-sale" || hash == "/property/new-homes/for-sale/") {
    navigate(`/property/new-homes/for-sale/in-london/`)
  }
  else if(hash == "/property/new-homes/to-rent" || hash == "/property/new-homes/to-rent/") {
    navigate(`/property/new-homes/to-rent/in-london/`)
  }
  else if(hash == "/property/commercial/for-sale" || hash == "/property/commercial/for-sale/") {
    navigate(`/property/commercial/for-sale/in-london`)
  }
  else if(hash == "/property/commercial/to-rent" || hash == "/property/commercial/to-rent/") {
    navigate(`/property/commercial/to-rent/in-london`)
  }
  // if(hash === "#book_a_valuation") {
  //   navigate(`/market-your-property/book-a-valuation/`)
  // }

  if(pageurl.indexOf('careers') !== -1) {
    setLogo("careers")
  }
  else if(pageurl.indexOf('/local-loving-life') !== -1) {
    setLogo("love")
  }
  else if((pageurl.indexOf('/loving-life') !== -1) || (pageurl.indexOf('/restaurant-and-bars') !== -1) || (pageurl.indexOf('/shops') !== -1) || (pageurl.indexOf('/events') !== -1) || (pageurl.indexOf('/clubs-and-societies') !== -1)) {
    setLogo("love")
  }
  else {
    setLogo("")
  }

  document.querySelector('body').classList.remove('stop-overflow')
    window.addEventListener("scroll", () => {
      lastScrollY = window.scrollY
      if(props.page == "results") {
      if (lastScrollY > 200) {

        $("header").hide();
      } else {
        $("header").show();
      }

      }
      else if(props.page == "contact") {
        $("header").show();
        setScroll(false)

      }
      else {
      $("header").show();
      setScroll(window.scrollY > 40)
      }
    })
  }, [])

  const addClass = () => {
    setAddclassname(!addclassname)
    let domElement=document.querySelector('.stop-overflow')
    if(domElement == null){
      document.querySelector('body').classList.add('stop-overflow')
      $(".submenu-sec").show();
    }
    else{
      document.querySelector('body').classList.remove('stop-overflow')
      //$(".submenu-sec").hide();
      setSubmenuclass(false)
      setNavLinkClass(false)
      setNavLinkClass2(false)
      setNavLinkClass21(false)
      setNavLinkClass3(false)
      setNavLinkClass31(false)
      setNavLinkClass4(false)
      setNavLinkClass5(false)
      setNavLinkClass6(false)
    }

    //console.log("dommminggggg",domElement);
  }

  const data = useStaticQuery(graphql`
    query{
    allStrapiMenuBurgers(filter: {Publish: {eq: true}}, sort: {fields: Sort, order: ASC}) {
        edges {
          node {
            Parent_Label
            Parent_URL {
              id
            }
            Sub_Menus {
              Parent_Label
              Parent_URL {
                id
              }
              Add_Sub_Menus {
                Label
                URL {
                  id
                }
              }
            }
            Columns
          }
        }
    }

    strapiSiteConfig {
      Facebook_Link
      Instagram_Link
      Linkedin_Link
      Twitter_Link
      Youtube_Link
    }


    }
  `);

  function searchOverlay(e) {
    $("body")
        .addClass("modal-open")
        .append("<div class='search-modal modal-backdrop fade'></div>")
    $(".search-overlay").addClass("active-search")
  }

  const pathname = typeof window !== 'undefined' ? window.location.pathname : ''

  return(
  <div className={scroll ? 'secondary-nav' : ''}>
  <header className={
    addclassname ? "header header-two active-header" : hoverclass || hoverclass2 || hoverclass3 || hoverclass4 || hoverclass5 || hoverclass6 ? "header header-two mouse-active" : scroll ? "header header-two-main" : removeClassname ? "header header-two" : "header header-two"
  }>

  <SearchOverlay/>

  <Container fluid className="d-flex justify-content-between align-items-center">
    {logo != 'careers' && logo != 'love' ?
    <Link className="nav-brand" to="/">
      <img loading="lazy" className="logo-white" src={logoImg} alt="Martyn Gerrard logo"/>
      <img loading="lazy" className="logo-black" src={logoBlack} alt="Martyn Gerrard logo"/>
    </Link> : logo != 'careers' && logo == 'love' ?
    <Link className="nav-brand" to="/">
      <img loading="lazy" className="logo-white" src={lovelogoBlack} alt="Martyn Gerrard logo"/>
      <img loading="lazy" className="logo-black" src={lovelogoImg} alt="Martyn Gerrard logo"/>
    </Link> :
    <Link className="nav-brand" to="/">
      <img loading="lazy" className="logo-white" src={careerlogoBlack} alt="Martyn Gerrard logo"/>
      <img loading="lazy" className="logo-black" src={careerlogoImg} alt="Martyn Gerrard logo"/>
    </Link>
    }
     {!props.hidemenu &&
     <>
    <div className="responsive-menu d-flex align-items-center d-lg-none">
      <Link className="search-icn" to="#" onClick={searchOverlay}>
        <i className="icon-search-icon"></i>
      </Link>
      <strong className="toggle-strip d-flex align-items-center"
        onClick={e=>addClass()}>
          <span>&nbsp;</span>
      </strong>
    </div>
    <Nav className="menu-wrap  d-lg-flex"
        activeKey="/home"
        // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
      >

        {data.allStrapiMenuBurgers.edges.map(({ node }, i) => {
          let url = GetURL(node.Parent_URL.id)
          return <>
            {node.Sub_Menus.length === 0 &&
              <Nav.Item>
                <Link className={navLinkClass6 ? "nav-click nav-link" : "nav-link"}  to={`/${url}`}>{node.Parent_Label}</Link>
              </Nav.Item>
            }

            {node.Sub_Menus.length !== 0 && node.Columns === 'Multiple' &&
        <Nav.Item>

          <Link to={`/${url}`} className="head-link desktop-submenu-nav"><div className={ navLinkClass ? "nav-click nav-link"  : "nav-link"} onMouseEnter={()=>setHoverclass(true)} onMouseLeave={()=>setHoverclass(false)}  onClick={()=>setNavLinkClass(true)}>{node.Parent_Label} <span className="arrow d-lg-none"><i className="icon-arrow-right"></i></span></div></Link>

          <div className={ navLinkClass ? "nav-click nav-link mobile-submenu-nav"  : "nav-link mobile-submenu-nav"} onMouseEnter={()=>setHoverclass(true)} onMouseLeave={()=>setHoverclass(false)}  onClick={()=>setNavLinkClass(true)}><Link to={`/${url}`} className="head-link">{node.Parent_Label}</Link> <span className="arrow d-lg-none"><i className="icon-arrow-right"></i></span></div>

            <div className="submenu-sec" >
            <div className="submenu-wrap">
              <Container>
                  <Link onClick={e=>removeClass(e,true)} id='back-icon' className="back-btn text-uppercase d-lg-none" to="#">
                      <span  className="back-arrow">
                      <i className="icon-arrow-right"></i>
                      </span> back to main menu
                  </Link>
                  <Accordion>
                  {node.Sub_Menus.map((Sub_Menus, i) => {
                    let sub_menu_url = ''
                    if(Sub_Menus.Parent_URL) {
                    sub_menu_url = GetURL(Sub_Menus.Parent_URL.id)
                    }
                    return <>
                      <Card className={i === 6 ? "block-management" : ''}>
                          <Card.Header className={toggleActive === i+1 ? "minus" : ''}>
                          <Accordion.Toggle as={Button} onClick={e=>toggleClick(i+1)} variant="link" eventKey={i+1}>
                              {sub_menu_url ?
                                  <Link to={`/${sub_menu_url}`} className="sub-head">{Sub_Menus.Parent_Label}</Link>
                                :
                                  <span className="sub-head-no-line">{Sub_Menus.Parent_Label}</span>
                              }
                          </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={i+1}>
                          <Card.Body>
                          <ul className="menu-nav">
                            {Sub_Menus.Add_Sub_Menus.map((Add_Sub_Menus, i) => {
                            let sub_url = GetURL(Add_Sub_Menus.URL.id)
                              return <li key={i}>
                                <Link to={`/${sub_url}`}><span>{Add_Sub_Menus.Label}</span></Link>
                              </li>
                            })}
                          </ul>


                              {i === 3 ? <>
                              {node.Sub_Menus.slice(6).map((Sub_Menus, i) => {
                              let url = GetURL(Sub_Menus.Parent_URL.id)
                              return (
                                <>
                                            {Sub_Menus.Parent_Label === "Block Management" &&
                              <div className="block-management-desktop">
                              <div className="btn">
                              <Link to={`/${url}`} className="sub-head">{Sub_Menus.Parent_Label}</Link>
                              </div>
                              <ul className="menu-nav">
                                {Sub_Menus.Add_Sub_Menus.map((Add_Sub_Menus, i) => {
                                  let sub_menu_url = GetURL(Add_Sub_Menus.URL.id)
                                  return <li><Link to={`/${sub_menu_url}`}><span>{Add_Sub_Menus.Label}</span></Link></li>
                                })}
                              </ul>
                              </div>
                                 }
                                          </>
                                )
                              })}
                              </> : ''}

                          </Card.Body>
                          </Accordion.Collapse>
                      </Card>
                        </>
                      })}
                  </Accordion>
              </Container>
            </div>
          </div>
        </Nav.Item>
            }


            {node.Sub_Menus.length !== 0 && node.Columns === 'Single' &&
              <Nav.Item>

                {node.Parent_Label === 'News & Guides' ?
                <>
                <Link to={`/${url}`} className="head-link desktop-submenu-nav"><div className={ navLinkClass3 ? "nav-click nav-link"  : " nav-link"} onClick={()=>setNavLinkClass31(true)}>{node.Parent_Label} <span className="arrow d-lg-none"><i className="icon-arrow-right"></i> </span></div></Link>

                <div className={ navLinkClass3 ? "nav-click nav-link mobile-submenu-nav"  : " nav-link mobile-submenu-nav"} onClick={()=>setNavLinkClass3(true)}><Link to={`/${url}`} className="head-link">{node.Parent_Label}</Link> <span className="arrow d-lg-none"><i className="icon-arrow-right"></i> </span></div>
                </>
                :
                <>
                <Link to={`/${url}`} className="head-link desktop-submenu-nav"><div className={ navLinkClass2 ? "nav-click nav-link"  : " nav-link"} onClick={()=>setNavLinkClass21(true)}>{node.Parent_Label} <span className="arrow d-lg-none"><i className="icon-arrow-right"></i> </span></div></Link>

                <div className={ navLinkClass2 ? "nav-click nav-link mobile-submenu-nav"  : " nav-link mobile-submenu-nav"} onClick={()=>setNavLinkClass2(true)}><Link to={`/${url}`} className="head-link">{node.Parent_Label}</Link> <span className="arrow d-lg-none"><i className="icon-arrow-right"></i> </span></div>
                </>

                }

                    <div className="submenu-sec single-col-submenu" >
                    <div className="submenu-wrap">
                      <Container>
                          <Link onClick={e=>removeClass(i,true)} id='back-icon' className="back-btn text-uppercase d-lg-none" to="#">
                              <span  className="back-arrow">
                              <i className="icon-arrow-right"></i>
                              </span> back to main menu
                          </Link>
                          <Accordion>
                          <ul>
                          {node.Sub_Menus.map((Sub_Menus, i) => {
                            let sub_menu_url = GetURL(Sub_Menus.Parent_URL.id)
                            return <li>
                                  <Link to={`/${sub_menu_url}`} className="sub-head">{Sub_Menus.Parent_Label}</Link>
                              </li>
                            })}
                          </ul>
                          </Accordion>
                      </Container>
                    </div>
                  </div>
              </Nav.Item>
            }

          </>
        })}

        <Nav.Item className="d-none d-lg-block search-icon-wrap">
          <Nav.Link eventKey="link-2" onClick={searchOverlay}><i className="icon-search-icon"></i></Nav.Link>
        </Nav.Item>
      </Nav>
      </>
     }
  </Container>

    {/* <SubMenu
      removeClass={removeClass}
      issubmenuclass={submenuclass}
     /> */}

  </header>
  </div>
)
  }

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header