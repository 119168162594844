import { useStaticQuery, graphql } from "gatsby";

import cardImg01 from "../../../images/card-img01.jpg"

export const GetMenuTileImageURL = (id) => {
  const data_image = useStaticQuery(graphql`
    query {
	  allStrapiAllMenus {
	    edges {
	      node {
	        URL
	        Alias
	        Tile_Image {
	          publicURL
	          childImageSharp {
	            fixed {
	              srcWebp
	            }
	          }
	        }
	        id
	        strapiId
	      }
	    }
	  }
    }
  `);

	let PageURL = data_image.allStrapiAllMenus.edges.filter(item => item.node.strapiId === id).pop().node;

	let URL = ''
	if(PageURL.Tile_Image !== null)
	{	
 		URL = PageURL.Tile_Image.publicURL
 		if(PageURL.Tile_Image?.childImageSharp?.fixed?.srcWebp !== null)
		{
			URL = PageURL.Tile_Image?.childImageSharp?.fixed?.srcWebp
		}
	}
	else {	
 		URL = cardImg01
	}
	
	return URL;

}