import React, { useState } from "react"
import { graphql } from 'gatsby'
import {Col, Row, Container } from 'react-bootstrap';
import HeaderTwo from "../components/headerTwo/headerTwo"
import Footer from "../components/footer/footer"
import CanHelp from "../components/Home/CanHelp/CanHelp"
import ReactMarkdown from "react-markdown"
import _ from "lodash"

import CareerVideosHead from "../components/videos/career-videos-head"
import VideoPost from "../components/News/NewsPost/VideoPost"
import Breadcrumbs from "../components/MgServices/Breadcrumb/news-breadcrumb"
import FilterCareerVideos from "../components/videos/filter-career-videos"


const VideoSubLanding = (props) => {

	const data = props.data.strapiVideosCategories
	const allmenus = props.data.allStrapiAllMenus.edges

	var videos = [];
	
	videos = props.data.allStrapiVideos.edges.filter(item => {
		let main_category = item.node.Video_Category.filter(cat => _.includes(cat, props.data.strapiVideosCategories.Name));
		return main_category.length > 0
	});

	// console.log("videos",videos)

	// for(var i=0; i < props.data.allStrapiVideos.edges.length; i++){
	// 	videos.push(props.data.allStrapiVideos.edges[i].node)
	// }

	if(videos) {
		videos.map((item, i) => {
			// console.log("video",item)
			videos[i] = item.node
		})
	
		//  videos[0] = videos[0].node
	}

	//  console.log("videos1",videos)
  	const [ search_text, setSearchText ] = useState('');

	const handlechange = (event) => {
	    // remove initial empty spaces
	    var search_val = event.target.value.trimStart();
	    setSearchText(search_val);
	}

	return(
		<div className="video-main-landing">
			<HeaderTwo />
			<div className="content">
			<div className="mg-services">
				<Container>
					<Row>
						<Col xs={12}>
							<div className="mgservice-head text-center">
								<div className="service-details">
									<Breadcrumbs url="career-videos" tag="contact" Title={data.Name} />
									<ReactMarkdown source={data.Heading} allowDangerousHtml className="" />
								</div>
							</div>
							<CareerVideosHead tag={data.Name} handlechange={handlechange}/>
						    {videos.length === 0 ?
						    	<p className="no-video">No videos found, please check after some times</p> : ''
						    }
						    {search_text !== '' ? <FilterCareerVideos url_tag="career" text={search_text} data={videos} menus={allmenus}/> : 
						    <VideoPost data={videos} url_tag="land" menus={allmenus}/> 
							}
						</Col>
					</Row>
				</Container>
			</div>
			</div>
			<CanHelp />
			<Footer />
		</div>
    )
}

export default VideoSubLanding


export const pageQuery = graphql`
  query VideoSubLandingQuery($slug: String!) {
      strapiVideosCategories(URL: {eq: $slug}) {
		Heading
    	Name
    	URL
	    videos {
	      Title
	      URL
	      Tile_Image {
	          internal {
	            description
	          }
	      }
	      Embed_Video_URL
	      Date
	      id
          imagetransforms {
            Tile_Image_Transforms
          }
	    }
      }

	  allStrapiVideos(sort: {order: DESC, fields: Date}, filter: {Publish: {eq: true}}) {
		edges {
		  node {
			id
			Title
			Date
			description
			URL
			Embed_Video_URL
			Meta_Description
			Meta_Title
			Tile_Image {
			  internal {
				description
			  }
			}
			imagetransforms {
			  Tile_Image_Transforms
			}
			Video_Category {
			  Name
			  URL
			  id
			}
			Videos_Parent_Category {
			  URL
			}
		  }
		}
	  }


	  allStrapiAllMenus {
	    edges {
	      node {
	        URL
	        Alias
	        Sub_Parent {
	          Alias
	          URL
	          id
	        }
	        Main_Parent {
	          Alias
	          URL
	          id
	        }
	        Link_Type
	        id
	        strapiId
	      }
	    }
	  }

    }
`

